<template>
    <div>
        <b-container v-if="showForm" class="register_page_header_block" fluid>
            <b-container>
                <div class="fs-52 fw-600 white-color pt-3">Регистрация заказчика</div>
                <b-tabs v-model="tabIndex" class="w-100 register_customer py-3" content-class="invisible">
                    <b-tab>
                        <registration-tab-header slot="title" :has-errors="$v.form.organization.$invalid" number="01"> Регистрационные и контактные данные организации</registration-tab-header>
                    </b-tab>
                    <b-tab>
                        <registration-tab-header slot="title" :has-errors="$v.form.user.$invalid || $v.form.poa.$invalid" number="02"> Идентификационные данные пользователя</registration-tab-header>
                    </b-tab>
                    <b-tab>
                        <registration-tab-header slot="title" :has-errors="$v.form.check_rules.$invalid" number="03"> Завершение создания заявления</registration-tab-header>
                    </b-tab>
                </b-tabs>
            </b-container>
        </b-container>
        <b-container v-if="showForm" fluid>
            <b-container>
                <b-tabs v-model="tabIndex" class="w-100" nav-class="invisible">
                    <b-tab>
                        <text-header>Данные организации</text-header>
                        <text-row :value="form.organization.full_name" label="Полное наименование" />
                        <text-row :value="form.organization.short_name" label="Сокращенное наименование" />
                        <text-row :value="form.organization.manager" label="Руководитель" />
                        <text-row :value="form.organization.inn" label="ИНН" />
                        <text-row v-if="selectedCertificateType === 'legal'" :value="form.organization.kpp" label="КПП" />
                        <text-row v-if="selectedCertificateType === 'legal'" :value="form.organization.ogrn" label="ОГРН" />
                        <text-row v-if="selectedCertificateType === 'individual'" :value="form.organization.ogrn" label="ОГРНИП" />
                        <form-row-single-select
                            v-model="form.organization.budgetary_manager_id"
                            :disabled="form.organization.municipality_id !== null"
                            :options="directories.budgetaryManagers"
                            :v="$v.form.organization.budgetary_manager_id"
                            allow-empty
                            description="или"
                            label="ГРБС"
                            placeholder="Выберите ГРБС" />
                        <form-row-single-select
                            v-model="form.organization.municipality_id"
                            :disabled="form.organization.budgetary_manager_id !== null"
                            :options="directories.municipalities"
                            :v="$v.form.organization.municipality_id"
                            allow-empty
                            label="Муниципалитет"
                            placeholder="Выберите Муниципалитет" />

                        <text-header>Контактная информация</text-header>
                        <form-row-edit-dadata-address v-model="form.organization.post_address" :v="$v.form.organization.post_address" label="Адрес почтовый" />
                        <form-row-edit-dadata-address v-model="form.organization.fact_address" :v="$v.form.organization.fact_address" label="Адрес фактический" />
                        <form-row-edit-email
                            :email-confirmed="form.organization.email_confirmed"
                            :v="$v.form.organization.email"
                            :value="form.organization.email"
                            label="Адрес электронной почты"
                            with-validation
                            @value="form.organization.email = $event"
                            @email-confirmed="form.organization.email_confirmed = $event" />
                        <form-row-edit-phone :v="$v.form.organization.phone" :value="form.organization.phone" label="Телефон" @value="form.organization.phone = $event" />
                        <form-row-edit-text v-model="form.organization.site" label="Адрес сайта" />
                        <form-row-edit-text v-model="form.organization.contact_name" :v="$v.form.organization.contact_name" label="ФИО контактного лица при осуществлении закупок" />
                    </b-tab>
                    <b-tab>
                        <text-header>Идентификационные данные пользователя</text-header>
                        <text-row :value="form.user.name" label="ФИО" />
                        <text-row :value="form.user.inn" label="ИНН" />
                        <text-row :value="form.user.snils" label="СНИЛС" />
                        <form-row-edit-text v-model="form.user.password" :v="$v.form.user.password" label="Пароль" type="password" @value="form.user.password = $event" autocomplete="new-password" />
                        <form-row-edit-text v-model="form.user.password_confirmation" :v="$v.form.user.password_confirmation" label="Подтверждение пароля" type="password" @value="form.user.password_confirmation = $event" autocomplete="new-password" />
                        <form-row-edit-email :email-confirmed="form.user.email_confirmed" :v="$v.form.user.email" :value="form.user.email" label="Адрес электронной почты" with-validation @value="form.user.email = $event" @email-confirmed="form.user.email_confirmed = $event" autocomplete="email" />
                        <form-row-edit-phone v-if="$isPhoneConfirmationNeeded" :phone-confirmed="form.user.phone_confirmed" :v="$v.form.user.phone" :value="form.user.phone" label="Телефон" with-validation @value="form.user.phone = $event" @phone-confirmed="form.user.phone_confirmed = $event" />
                        <form-row-edit-phone v-else :v="$v.form.user.phone" :value="form.user.phone" label="Телефон" @value="form.user.phone = $event" />
                        <form-row-single-select v-model="form.user.post" :options="postOptions" :v="$v.form.user.post" autocomplete="organization-title" label="Должность" placeholder="Выберите должность" />
                        <form-row-upload-poa v-if="needToHavePoa" v-model="form.poa" label="МЧД" :v="$v.form.poa" :organization-inn="form.organization.inn" :user-inn="form.user.inn" :user-snils="form.user.snils" />
                    </b-tab>
                    <b-tab>
                        <text-header>Завершение создания заявления</text-header>
                        <registration-confirmation-text-block />
                        <b-form-group :state="!$v.form.check_rules">
                            <b-form-checkbox v-model="form.check_rules" :state="!$v.form.check_rules">
                                <span class="grey-color fw-600">Подтверждаю перечисленные выше условия</span>
                            </b-form-checkbox>
                        </b-form-group>
                    </b-tab>
                </b-tabs>
                <div class="my-5">
                    <b-button v-if="tabIndex > 0" class="mx-2 text-uppercase" variant="custom-outline-secondary" @click="previousTab">Назад</b-button>
                    <b-button v-if="tabIndex < 2" class="mx-2 text-uppercase" variant="custom-green" @click="nextTab">Далее</b-button>
                    <b-button v-if="tabIndex === 2" :disabled="$v.form.$invalid" class="mx-2 text-uppercase" variant="custom-green" @click="makeXml">Готово</b-button>
                    <span v-if="tabIndex === 2 && $v.form.$invalid" class="text-danger mx-2">Заполните все обязательные поля на каждой вкладке.</span>
                </div>
            </b-container>
        </b-container>
        <b-modal ref="select-organization" v-model="showOrgModal" centered no-close-on-backdrop no-close-on-esc title="Укажите ИНН организации">
            <div class="d-block text-left">
                <form-row-edit-text v-model.trim="inn" label="ИНН" />
                <form-row-edit-text v-if="foundOrganizationBranches || kpp" v-model.trim="kpp" label="КПП" />
                <b-row>
                    <b-col class="mt-3" md="12">
                        <b-button :disabled="isFind" class="text-uppercase float-right" variant="custom-green" @click="getOrganizationInformation">
                            <b-spinner v-if="isFind" small></b-spinner>
                            Найти
                        </b-button>
                    </b-col>
                </b-row>
                <b-row v-if="!!tmpOrganization" class="py-3">
                    <b-col class="fs-16 grey-color"> Это ваша организация ?</b-col>
                    <b-col class="fs-16 grey-color fw-600">
                        {{ tmpOrganization.full_name }}
                    </b-col>
                </b-row>
            </div>
            <div slot="modal-footer" class="w-100">
                <b-button class="mr-2 text-uppercase" variant="custom-outline-secondary" @click="cancelCertificate">Отмена</b-button>
                <b-button :disabled="tmpOrganization == null" class="text-uppercase" variant="custom-green" @click="showCreateForm">Подтвердить</b-button>
            </div>
        </b-modal>
        <b-modal ref="select-cert-modal" v-model="showCertificateSelectModal" centered hide-header-close no-close-on-backdrop no-close-on-esc title="Выберите сертификат">
            <div class="d-block text-left">
                <multiselect
                    v-model="selectedCertificate"
                    :allow-clear="false"
                    :allow-empty="false"
                    :close-on-select="true"
                    :multiple="false"
                    :options="certificates"
                    :preserve-search="false"
                    :show-labels="false"
                    label="subjectName"
                    placeholder="Выберите сертификат"
                    track-by="thumbprint"
                    @input="setSelectedCertificateOwnerInfo">
                    <span slot="noOptions">Нет данных</span>
                    <span slot="noResult">По вашему запросу сертификаты не найдены</span>
                    <template slot="singleLabel" slot-scope="props">
                        <strong>Субъект:</strong> {{ props.option.subjectName }}<br />
                        <br />
                        <strong>Срок действия:</strong> с {{ getDateFormat(props.option.validFrom) }} по {{ getDateFormat(props.option.validTo) }}
                    </template>
                    <template slot="option" slot-scope="props">
                        <strong>Субъект:</strong> {{ props.option.subjectName }}<br />
                        <br />
                        <strong>Срок действия:</strong> с {{ getDateFormat(props.option.validFrom) }} по {{ getDateFormat(props.option.validTo) }}
                    </template>
                </multiselect>
            </div>
            <div slot="modal-footer" class="w-100">
                <b-button class="mr-2 text-uppercase" variant="custom-outline-secondary" @click="cancelCertificate">Отмена</b-button>
                <b-button :disabled="!selectedCertificate" class="text-uppercase" variant="custom-green" @click="checkCertificate"> Продолжить</b-button>
            </div>
        </b-modal>
        <b-modal ref="sign-form-modal" v-model="showSignModal" centered no-close-on-backdrop no-close-on-esc scrollable size="lg" title="Подписать заявление" @close="cancelSignModal">
            <div class="d-block text-left">
                <div class="d-block text-left">
                    <b-tabs content-class="mt-3">
                        <b-tab active>
                            <template slot="title">
                                <span class="system_link-style fs-16">Форма</span>
                            </template>
                            <div class="px-3">
                                <text-header>Регистрационные данные организации</text-header>
                                <text-row :value="form.organization.short_name" label="Сокращенное наименование" />
                                <text-row :value="form.organization.manager" label="Руководитель" />
                                <text-row :value="form.organization.inn" label="ИНН" />
                                <text-row v-if="selectedCertificateType === 'legal'" :value="form.organization.kpp" label="КПП" />
                                <text-row v-if="selectedCertificateType === 'legal'" :value="form.organization.ogrn" label="ОГРН" />
                                <text-row v-if="selectedCertificateType === 'individual'" :value="form.organization.ogrn" label="ОГРНИП" />

                                <text-header>Контактные данные организации</text-header>
                                <text-row :value="form.organization.post_address" label="Адрес почтовый" />
                                <text-row :value="form.organization.fact_address" label="Адрес фактический" />
                                <text-row :value="form.organization.email" label="Адрес электронной почты" />
                                <text-row-phone :value="form.organization.phone" label="Телефон" />
                                <text-row :value="form.organization.site" label="Адрес сайта" />
                                <text-row :value="form.organization.fax" label="Факс" />
                                <text-row :value="form.organization.contact_name" label="ФИО контактного лица при осуществлении закупок" />

                                <text-header>Идентификационные данные пользователя</text-header>
                                <text-row :value="form.user.name" label="ФИО" />
                                <text-row :value="form.user.inn" label="ИНН" />
                                <text-row :value="form.user.snils" label="СНИЛС" />
                                <text-row :value="form.user.email" label="Адрес электронной почты" />
                                <text-row-phone :value="form.user.phone" label="Телефон" />
                            </div>
                        </b-tab>
                        <b-tab>
                            <template slot="title">
                                <span class="system_link-style fs-16">Xml версия</span>
                            </template>
                            <pre class="fs-10 grey-color p-3 greyBg m-0">{{ signedFormXml }}</pre>
                        </b-tab>
                    </b-tabs>
                </div>
            </div>
            <div slot="modal-footer" class="w-100">
                <text-row :value="selectedCertificate?.subjectName" label="Сертификат" />
                <b-button class="mr-2 text-uppercase" variant="custom-outline-secondary" @click="cancelSignModal">Отмена</b-button>
                <b-overlay v-if="!isSignedXml" :show="isSigningXml" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                    <b-button v-if="!isSignedXml" :disabled="!selectedCertificate" class="text-uppercase" variant="custom-green" @click="signForm">Подписать</b-button>
                </b-overlay>
                <b-overlay v-if="isSignedXml" :show="isDataSending" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                    <b-button v-if="isSignedXml" :disabled="!selectedCertificate" class="text-uppercase" variant="custom-green" @click="register">Зарегистрироваться</b-button>
                </b-overlay>
            </div>
        </b-modal>
    </div>
</template>

<script>
import RegistrationTabHeader from '@/components/auth/parts/registration-tab-header';
import { email, helpers, minLength, required, requiredIf, sameAs } from 'vuelidate/lib/validators';
import RegistrationConfirmationTextBlock from '@/components/auth/parts/registration-confirmation-text-block';
import { getUserCertificates } from 'crypto-pro';
import { json2xml, prepareCertificateOwnerInfo } from '@/helpers';
import { $api } from '@/services/backend/api';
import FormRowUploadPoa from '@/components/common/form-rows/form-row-upload-poa.vue';

const phone = helpers.regex('phone', /^7[0-9+]{10}$/);
const allowable = helpers.regex('allowable', /^[a-zA-Z0-9-_=+~!@#$%^&*() ,.<>?/\\|`]+$/);
const containsSymbols = helpers.withParams({ type: 'containsSymbols' }, (value) => (value ? /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/.test(value) : true));

export default {
    name: 'RegisterCustomer',
    metaInfo: {
        title: 'Регистрация заказчика'
    },
    components: {
        FormRowUploadPoa,
        RegistrationConfirmationTextBlock,
        RegistrationTabHeader
    },
    data() {
        return {
            selectedCertificate: null,
            tabIndex: 0,
            certificates: [], // сертификаты для селекта
            showCertificateSelectModal: false, // modal выбора сертификатов
            showSignModal: false, // modal подписи формы
            showOrgModal: false, // modal для получения информации об организации по ИНН
            showForm: false, // отображение регистрационной формы
            formXml: '', // заготовка под xml для подписи
            signedFormXml: '', // заготовка под xml для подписи
            isSignedXml: false, // подписана ли форма
            isSigningXml: false,
            isDataSending: false,
            inn: '', // ИНН организации для поиска
            kpp: '',
            foundOrganizationBranches: false,
            isFind: false, // найдена ли организация (для прелоадера)
            tmpOrganization: null, // временное хранилище для найденной организации
            selectedCertificateOwnerInfo: null,
            postOptions: [
                { id: 'Руководитель организации', name: 'Руководитель организации' },
                { id: 'Администратор организации', name: 'Администратор организации' },
                { id: 'Лицо, уполномоченное на размещение информации и документов', name: 'Лицо, уполномоченное на размещение информации и документов' },
                { id: 'Лицо, имеющее право подписи документов от имени организации', name: 'Лицо, имеющее право подписи документов от имени организации' }
            ],
            form: {
                organization: {
                    full_name: '',
                    short_name: '',
                    inn: '',
                    kpp: '',
                    ogrn: '',
                    post_address: '',
                    fact_address: '',
                    email: '',
                    email_confirmed: false,
                    phone: '',
                    site: '',
                    contact_name: '',
                    manager: '',
                    municipality_id: null,
                    budgetary_manager_id: null
                },
                user: {
                    name: '',
                    inn: null,
                    snils: null,
                    password: '',
                    password_confirmation: '',
                    email: '',
                    phone: '',
                    post: null,
                    email_confirmed: false,
                    phone_confirmed: false
                },
                check_rules: false,
                poa: null
            },
            directories: {
                municipalities: [],
                budgetaryManagers: []
            }
        };
    },
    validations() {
        return {
            form: {
                organization: {
                    post_address: { required },
                    fact_address: { required },
                    email: { required, email },
                    email_confirmed: { checked: (value) => value === true },
                    phone: { required, phone },
                    contact_name: { required },
                    municipality_id: { required: requiredIf((organization) => !organization.budgetary_manager_id) },
                    budgetary_manager_id: { required: requiredIf((organization) => !organization.municipality_id) }
                },
                user: {
                    name: { required, minLength: minLength(2) },
                    inn: { required },
                    snils: { required },
                    password: { required, minLength: minLength(8), allowable, containsSymbols },
                    password_confirmation: {
                        required,
                        confirmPassword: sameAs((form) => form.password)
                    },
                    email: { required, email },
                    email_confirmed: { checked: (value) => value === true },
                    phone: { required, phone },
                    phone_confirmed: { checked: (value) => value === true || !this.$isPhoneConfirmationNeeded },
                    post: { required },
                    signs_with_poa: {}
                },
                poa: { required: requiredIf((form) => form.user.signs_with_poa) },
                check_rules: { checked: (value) => value === true }
            }
        };
    },
    async mounted() {
        await this.fillData();
        this.showCertificateSelectModal = true;
    },
    methods: {
        nextTab() {
            this.tabIndex++;
            this.$scrollToTop();
        },
        previousTab() {
            this.tabIndex--;
            this.$scrollToTop();
        },
        async fillData() {
            this.certificates = await getUserCertificates();
            this.directories.municipalities = this.$globalDictionaries.municipalities;
            this.directories.budgetaryManagers = this.$globalDictionaries.budgetaryManagers;
        },
        async setSelectedCertificateOwnerInfo(certificate) {
            if (!certificate) {
                this.selectedCertificateOwnerInfo = null;
            }
            this.selectedCertificateOwnerInfo = await prepareCertificateOwnerInfo(certificate);
        },
        async checkCertificate() {
            if (await this.$api.authentication.checkCertificate(this.selectedCertificate, this.selectedCertificateOwnerInfo, true)) {
                this.showCertificateSelectModal = false;
                this.inn = this.selectedCertificateOwnerInfo.innul;
                this.showOrgModal = true;
                this.$store.commit('set_current_thumbprint', this.selectedCertificate.thumbprint);
            }
        },
        cancelSignModal() {
            this.isSignedXml = false;
            this.showSignModal = false;
        },
        // поиск организации по ИНН в дадата
        async getOrganizationInformation() {
            this.isFind = true;

            this.$api.authentication
                .loadOrganization(this.inn, this.kpp, false, this.selectedCertificate)
                .then((response) => {
                    this.foundOrganizationBranches = false;
                    this.tmpOrganization = response.data;
                })
                .catch((error) => {
                    if (error.response.status === 406) this.foundOrganizationBranches = true;
                    this.tmpOrganization = null;
                    this.pushToast({
                        text: error.response.data.message ? error.response.data.message : 'Организация не найдена',
                        title: 'Ошибка',
                        variant: 'danger'
                    });
                })
                .finally(() => {
                    this.isFind = false;
                });
        },
        // после выбора сертификата показываем форму организации и заполняем данными с сервера от дадаты
        async showCreateForm() {
            this.showForm = true;
            this.showOrgModal = false;
            this.form.organization = { ...this.form.organization, ...this.tmpOrganization };
            this.form.organization.is_isp = false;
            this.form.user.name = this.selectedCertificateOwnerInfo.last_name + ' ' + this.selectedCertificateOwnerInfo.first_middle_name;
            this.form.user.inn = this.selectedCertificateOwnerInfo.inn;
            this.form.user.snils = this.selectedCertificateOwnerInfo.snils;
            this.form.user.signs_with_poa = this.needToHavePoa;
            delete this.form.organization.post;
        },
        // если нажата "Отмена" при выборе сертификата
        cancelCertificate() {
            this.$refs['select-cert-modal'].hide();
            this.showForm = false;
            this.showOrgModal = false;
            this.pushToast({
                text: 'Работа на сайте возможна только при наличии ЭЦП',
                title: 'Ошибка',
                variant: 'danger'
            });
            this.$router.push('/auth/error').catch(() => {});
        },
        // формируем xml из json формы и показываем modal с данными для подписания
        makeXml() {
            let data = this.form;
            this.formXml = json2xml(data);
            this.formXml = '<?xml version="1.0" encoding="UTF-8"?>\n<application>' + this.formXml + '\n</application>';
            this.signedFormXml = this.formXml;
            this.showSignModal = true;
        },
        // подписание формы выбранным сертификатом
        async signForm() {
            this.isSigningXml = true;
            this.signedFormXml = await $api.signXML(this.formXml);
            this.isSignedXml = !!this.signedFormXml;
            this.isSigningXml = false;
        },
        async register() {
            if (this.isSignedXml) {
                this.isDataSending = true;
                const response = await this.$api.authentication.register(this.form.user, this.form.organization, this.selectedCertificate, this.signedFormXml, this.form.poa, false);
                if (response) {
                    this.showSignModal = false;
                    this.$store
                        .dispatch('login', {
                            token: response.access_token,
                            user: response.user,
                            organization: response.organization
                        })
                        .then(() => {
                            window.location.assign('/cabinet');
                        });
                }
                this.isDataSending = false;
            }
        }
    },
    computed: {
        selectedCertificateType() {
            if (!this.selectedCertificateOwnerInfo?.inn || !this.selectedCertificateOwnerInfo?.snils) {
                return null;
            }
            if (this.selectedCertificateOwnerInfo?.innul && this.selectedCertificateOwnerInfo?.ogrn) {
                return 'legal';
            } else if (this.selectedCertificateOwnerInfo?.ogrnip) {
                return 'individual';
            } else {
                return 'personal';
            }
        },
        selectedCertificateOrganizationInn() {
            switch (this.selectedCertificateType) {
                case 'legal':
                    return this.selectedCertificateOwnerInfo.innul;
                case 'individual':
                    return this.selectedCertificateOwnerInfo.inn;
                default:
                    return null;
            }
        },
        needToHavePoa() {
            return this.selectedCertificateOrganizationInn !== this.form.organization.inn;
        }
    }
};
</script>

<style scoped>
.register_page_header_block {
    background-image: url('/images/providers_head.svg');
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.head_register-list {
    color: var(--halfWhite-color);
}
</style>

<style>
.register_customer ul.nav-tabs {
    width: 100%;
    background: transparent;
}

.register_customer ul.nav-tabs li {
    width: 33.33%;
}

.register_customer ul.nav-tabs li a {
    border: none;
}

.register_customer .nav-link.active {
    border-bottom: 2px solid #fff;
    background: transparent;
}

.register_customer .nav-link.active .head_register-list {
    color: #fff;
}

.invisible {
    display: none;
}
</style>
